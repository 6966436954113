"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsListRepliesToComment = exports.ForumsListRepliesToCommentOperationId = void 0;
const ClientController_1 = require("../../../../../../../../ClientController");
exports.ForumsListRepliesToCommentOperationId = 'ForumsListRepliesToComment';
/**
 * @path /forums/:forumId/:postId/comments/:commentId/replies
 * @generated
 */
exports.ForumsListRepliesToComment = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/:postId/comments/:commentId/replies',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
